:root {
    --ak-accent: #fd4b2d;

    --ak-dark-foreground: #fafafa;
    --ak-dark-foreground-darker: #bebebe;
    --ak-dark-foreground-link: #5a5cb9;
    --ak-dark-background: #18191a;
    --ak-dark-background-darker: #000000;
    --ak-dark-background-light: #1c1e21;
    --ak-dark-background-light-ish: #212427;
    --ak-dark-background-lighter: #2b2e33;

    /* PatternFly likes to override global variables for some reason */
    --ak-global--Color--100: var(--pf-global--Color--100);

    /* Minimum width after which the sidebar becomes automatic */
    --ak-sidebar--minimum-auto-width: 80rem;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: var(--ak-accent);
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}

html {
    --pf-c-nav__link--PaddingTop: 0.5rem;
    --pf-c-nav__link--PaddingRight: 0.5rem;
    --pf-c-nav__link--PaddingBottom: 0.5rem;
    --pf-c-nav__link--PaddingLeft: 0.5rem;
}

html > form > input {
    position: absolute;
    top: -2000px;
    left: -2000px;
}

.pf-icon {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    vertical-align: middle;
}

.pf-c-page__header {
    z-index: 0;
    background-color: var(--ak-dark-background-light);
    box-shadow: var(--pf-global--BoxShadow--lg-bottom);
}

/*****************************
* Login adjustments
*****************************/
/* Ensure card is displayed on small screens */
.pf-c-login__main {
    display: block;
    position: relative;
    width: 100%;
    /* INFINCE START */
    box-shadow: 10px 10px 30px 4px #88888861;
    /* INFINCE END */
}
.ak-login-container {
    height: calc(100vh - var(--pf-global--spacer--lg) - var(--pf-global--spacer--lg));
    width: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.pf-c-login__header {
    flex-grow: 1;
}
.pf-c-login__footer {
    flex-grow: 2;
    display: flex;
    justify-content: end;
    flex-direction: column;
}
.pf-c-login__footer ul.pf-c-list.pf-m-inline {
    justify-content: center;
    padding: 2rem 0;
}
/*****************************
* End Login adjustments
*****************************/

.pf-c-content h1 {
    display: flex;
    align-items: flex-start;
}
.pf-c-content h1 i {
    font-style: normal;
}
.pf-c-content h1 :first-child {
    margin-right: var(--pf-global--spacer--sm);
}

/* ensure background on non-flow pages match */
.pf-c-background-image::before {
    background-image: var(--ak-flow-background);
    background-position: center;
}

.pf-m-success {
    color: var(--pf-global--success-color--100) !important;
}
.pf-m-warning {
    color: var(--pf-global--warning-color--100);
}
.pf-m-danger {
    color: var(--pf-global--danger-color--100);
}

.pf-c-description-list__description .pf-c-button {
    margin-right: 6px;
    margin-bottom: 6px;
}

/* Flow-card adjustments for static pages */
.pf-c-brand {
    padding-top: calc(
        var(--pf-c-login__main-footer-links--PaddingTop) +
            var(--pf-c-login__main-footer-links--PaddingBottom) +
            var(--pf-c-login__main-body--PaddingBottom)
    );
    max-height: 9rem;
}

.ak-brand {
    display: flex;
    justify-content: center;
    width: 100%;
}
.ak-brand img {
    padding: 0 2rem;
    max-height: inherit;
}

@media (min-height: 60rem) {
    .pf-c-login.stacked .pf-c-login__main {
        margin-top: 13rem;
    }
}

.pf-c-data-list {
    padding-inline-start: 0;
}


/* To be put in /account/css/authentik.css */

@media screen and (min-width: 1200px) {
    .pf-c-login__header {
        margin-top: 0px;
    }
}

.inf-login-header {
    display: block;
}


.inf-logo-wrapper {
    display: flex;
    align-items: flex-end;
}

.inf-logo-wrapper .inf-login-logo {
    margin: 0 auto 20px !important;
    text-align: center;
    width: 190px;
    padding: 0px;
    /* padding: 8px 12px; */
    /* background-color: #e42326 !important; */
}

.inf-logo-wrapper .inf-login-logo img {
    border-radius: 3px;
    -webkit-border-radius: 3px;
    max-height: 90px;
    padding: 0px;
}

.inf-login-footer {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
}

.inf-login-footer-links {
    list-style: none;
    color: rgb(67, 68, 70);
    font-weight: 400;
    font-size: 12px;
}

.inf-login-footer-links li {
    display: block;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    color: rgb(67, 68, 70);
}

.inf-login-footer-links li a{
    color: rgb(67, 68, 70);
    font-size: 12px;
    font-weight: 400;
}

.inf-login-footer-links li a:hover {
    text-decoration: none;
}

.inf-authentik-forgot {
    text-align: center;
}
.pf-c-empty-state{
    padding-top: 0px;
    padding-bottom: 0px;
}

.inf-authentik-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.inf-authentik-forgot a {
    /* color : rgb(0, 102, 204); */
    font-size: 15px;
    /* text-decoration: none; */

    color: var(--pf-global--link--Color);
    text-decoration: var(--pf-global--link--TextDecoration);
}

.passwordshow {
    position: absolute;
    padding-top: 0px;
    right: 10px;
    top: 32px;
}

.inf_password_container {
    position: relative;
}

.pf-c-login__main-footer .pf-c-button__icon {
    position: relative;
    padding-top: 25px;
    cursor: auto;
}
.pf-c-login__main-footer .pf-c-button__icon::before {
    content: '';
    position: absolute;
    top: 0px;
    left: calc(50% - 50px);
    width: 100px;
    height: 1px;
    border-top: 1px solid #dfdfdf;
}
.pf-c-login__main-footer .pf-c-button__icon::after {
    content: "Or";
    position: absolute;
    width: 30px;
    height: 20px;
    left: calc(50% - 15px);
    top: -10px;
    font-size: 13px;
    text-align: center;
    background-color: #fff;
    color: var(--ak-dark-background-lighter);
}

.pf-c-login__main-footer-links-item img {
    max-width: inherit;
    max-height: initial;
    width: 160px;
}


ak-flow-executor {
    height: 100%;
    overflow: auto;
    position: fixed;
    width: 100%;
}

.pf-c-login.sidebar_left .ak-login-container,
.pf-c-login.sidebar_right .ak-login-container {
    height: 100%;
    min-height: 100vh;
}

.pf-c-form-control[aria-invalid="true"] {
    background-image: none !important;
}

@media (prefers-color-scheme: dark) {
    .pf-c-nav__item .pf-c-nav__link,
    .pf-c-nav__link.user-logout {
        color: white;
    }
    .pf-c-nav__item .pf-c-nav__link:hover,
    .pf-c-nav__link.user-logout:hover {
        color: #151515;
    }
    .pf-c-nav__item .pf-c-nav__link:active {
        color: #151515;
    }
    .pf-c-nav__item .pf-c-nav__link:focus {
        color: #151515;
    }
    .pf-c-nav__item .pf-c-nav__link.pf-m-current {
        color: #151515;
    }
}
